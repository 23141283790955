<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
    style="margin-top: 100px"
  >
  
    <v-row justify="center">
        
        
      <v-col
        cols="12"
        md="6"
      >
      <v-btn text to="/auth/sign-in">
          <v-icon>mdi-arrow-left-thin</v-icon>
          Go Back</v-btn>
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Forgot Password
            </div>

          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    label="Username"
                    v-model="user.username"
                    
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    class="purple-input"
                    label="Email"
                    v-model="user.email"
                  />
                </v-col>

                  <v-col
                  cols="12"
                  md="12"
                  v-if="userFound"
                >
                  <v-text-field
                    class="purple-input"
                    label="New Password"
                    v-model="user.newPassword"
                  />
                </v-col>

                  <v-col
                  cols="12"
                  md="12"
                  v-if="userFound"
                >
                  <v-text-field
                    class="purple-input"
                    label="Otp Code"
                    v-model="user.forgotPasswordCode"
                  />
                </v-col>


                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="success"
                    class="mr-0"
                    @click="saveChanges"
                  >
                    Save Changes
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>

   
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data(){
      return{
        user:{

        },
        userFound: false,
      }
    },
    methods:{
        forgotPassword(){
            this.$Expert.forgotPassword(this.user).then(data=>{
                this.userFound = true;
        }).catch(error=>{
          if(error.response){
            this.$toastr.error(error.response.data.message);
          }else{
            this.$toastr.error("Something Went Wrong");
          }
          

        })
        },

        forgotPasswordChange(){
                this.$Expert.forgotPasswordChange(this.user).then(data=>{
                this.$toastr.success("Password changes successfully");
                this.$router.push({path:"/"});
        }).catch(error=>{
          if(error.response){
            this.$toastr.error(error.response.data.message);
          }else{
            this.$toastr.error("Something Went Wrong");
          }
          

        })
        },

      saveChanges(){
          if(this.userFound){
              this.forgotPasswordChange();
          }else{
              this.forgotPassword();
          }
        
      }
    }


  }
</script>
